<template>
  <v-container fluid grid-list-lg>
    <Create
      :createDialog="createDialog"
      :RelationId="RelationId"
      :TypeId="TypeId"
      @close="createDialog = false"
      @submit="getList"
    ></Create>
    <v-card :loading="loading" outlined>
      <ListTitle 
        :access="checkRightStatus(46)"
        title="Attachments"
        @add="add"
      />
      <v-dialog v-model="viewDialog" max-width="90%">
        <v-card v-if="selectedAttachment">
          <v-toolbar dense class="mb-3" flat color="grey lighten-3">
            <v-toolbar-title class="subtitle-1">
              {{ selectedAttachment.createdAt | moment("MM/DD/YYYY hh:mm A") }}
            </v-toolbar-title>
            <v-spacer> </v-spacer>
            <v-btn small icon @click.stop.prevent="viewDialog = !viewDialog">
              <v-icon small>close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="imageViewBox">
            <v-img max-height="500px" contain :src="selectedAttachment.url" />
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="viewDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text v-if="attachments.length !== 0">
        <v-list>
          <v-list-item v-for="attachment in attachments" :key="attachment.id">
            <v-list-item-avatar size="100" tile @click="openView(attachment)">
              <v-img :src="attachment.url"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="attachment.name"></v-list-item-title>
              <v-list-item-title>{{
                attachment.createdAt | moment("from", "now")
              }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-row no-gutters>
                <v-col>
                  <v-btn text icon @click="downloadFile(attachment)">
                    <v-icon>mdi-cloud-download-outline</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn text icon @click="deleteFile(attachment)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-text v-else>
        <div class="text-center">
          There is an any attachment to this account yet
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import attachmentService from "../service";
import { mapGetters } from "vuex";

const Create = () => import("./Create");
export default {
  props: {
    RelationId: {
      default: null,
    },
    TypeId: {
      default: 2,
    },
  },
  name: "attachment-list",
  data() {
    return {
      createDialog: false,
      editDialog: false,
      selectedAttachment: null,
      attachments: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "balance",
      pageCount: 0,
      itemsPerPage: 10,
      viewDialog: false,
    };
  },
  watch: {
    RelationId: {
      immediate: true,
      handler() {
        this.getList();
      },
    },
  },
  created() {
    // this.getList();
    // document.title = "smplsale - Attachment List";
  },
  components: {
    Create,
  },
  computed: {
    ...mapGetters("global", ["currentUser", "checkRightStatus"]),
  },
  methods: {
    openView(attachment) {
      this.selectedAttachment = attachment
      this.viewDialog = !this.viewDialog
    },
    downloadFile(attachment) {
      window.location = attachment.url;
    },
    add() {
      this.createDialog = !this.createDialog;
    },
    getList() {
      this.loading = true;
      let where = {
        RelationId: this.RelationId,
        TypeId: this.TypeId,
      };
      return attachmentService.getAll(where).then((response) => {
        this.loading = false;
        this.attachments = response.data;
        return response;
      });
    },
    deleteFile(attachment) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          attachmentService.delete(attachment.id).then((response) => {
            if (response.data) {
              this.$swal("Deleted!", "attachment has been deleted.", "success");
              this.getList();
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.imageViewBox {
  max-height: 650px;
  overflow: auto;
}
</style>
